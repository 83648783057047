import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';

export const fetchAbout = createAsyncThunk('about/fetchAbout', async () => {
  const response = await axios.get('about?populate=about_section');
  return response.data;
});

const initialState = {
  data: null,
  status: 'idle',
  error: null,
};

const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAbout.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAbout.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchAbout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default aboutSlice.reducer;

export const selectAboutData = (state) => state.about.data;

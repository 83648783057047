// src/store/fitnessSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';

// Define an async thunk for fetching fitness data
export const fetchFitnessData = createAsyncThunk(
  'fitness/fetchFitnessData',
  async () => {
    const response = await axios.get(
      '/fitness-page?populate=experts,experts.img,howItsWork,personalizedCoaching,PainFreeLife,PainFreeLife.img,howItsWork.img'
    );
    return response.data;
  }
);

const initialState = {
  fitnessData: null,
  status: 'idle',
  error: null,
};

// Create the slice
const fitnessSlice = createSlice({
  name: 'fitness',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFitnessData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFitnessData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.fitnessData = action.payload;
      })
      .addCase(fetchFitnessData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default fitnessSlice.reducer;

export const selectFitnessData = (state) => state.fitness.fitnessData;
export const selectFitnessStatus = (state) => state.fitness.status;
export const selectFitnessError = (state) => state.fitness.error;

'use client';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';

export const fetchTreatmentListDetails = createAsyncThunk(
  'treatmentListDetails/fetchTreatmentListDetails',
  async (slug) => {
    const queryString = `treatment-lists?filters[slug][$eq]=${slug}&populate=treatment_list_detail,treatment_list_detail.img,top_doctors,top_doctors.profile,treatment_list_detail.faqData,treatment_list_detail.costInfoAcrossIndia,treatment_list_detail.factors,treatment_list_detail.inclusions,treatment_list_detail.treatmentCost,treatment_list_detail.diagnosis,treatment_list_detail.testimonials`;
    const response = await axios.get(queryString);
    return response.data;
  }
);

const initialState = {
  data: null,
  status: 'idle',
  error: null,
};

const treatmentListDetailsSlice = createSlice({
  name: 'treatmentListDetails',
  initialState,
  reducers: {
    clearState: (state) => {
      state.data = null;
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTreatmentListDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTreatmentListDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchTreatmentListDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { clearState } = treatmentListDetailsSlice.actions;

export default treatmentListDetailsSlice.reducer;

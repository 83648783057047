'use client';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';
import toast from 'react-hot-toast';

export const postPartnersLead = createAsyncThunk(
  'partnersLeads/postPartnersLead',
  async (partnersLeadData) => {
    const response = await axios.post('partners-leads', partnersLeadData);
    return response.data;
  }
);

const partnersLeadsSlice = createSlice({
  name: 'partnersLeads',
  initialState: {
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postPartnersLead.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postPartnersLead.fulfilled, (state) => {
        state.status = 'succeeded';
        toast.success(`Consultation request successful`);
      })
      .addCase(postPartnersLead.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default partnersLeadsSlice.reducer;

'use client';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';
import toast from 'react-hot-toast';

export const postConsultation = createAsyncThunk(
  'consultation/postConsultation',
  async (consultationData) => {
    const response = await axios.post('consultation-leads', consultationData);
    return response.data;
  }
);

const consultationSlice = createSlice({
  name: 'consultation',
  initialState: {
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postConsultation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postConsultation.fulfilled, (state) => {
        state.status = 'succeeded';
        toast.success('Consultation request successful!');
      })
      .addCase(postConsultation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default consultationSlice.reducer;

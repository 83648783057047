'use client';
import { configureStore } from '@reduxjs/toolkit';
import treatmentsReducer from '@/slice/treatmentsSlice';
import topDoctorsSlice from '@/slice/topDoctorsSlice';
import topHospitalsSlice from '@/slice/topHospitalsSlice';
import howItWorksSlice from '@/slice/howItsWorkSlice';
import blogsSlice from '@/slice/blogsSlice';
import treatmentBannersSlice from '@/slice/treatmentBannersSlice';
import assistancesSlice from '@/slice/fetchAssistances';
import successStoriesSlice from '@/slice/successStoriesSlice';
import treatmentPackageSlice from '@/slice/treatmentPackageSlice';
import partnersLeadsSlice from '@/slice/partnerleadSlice';
import consultationSlice from '@/slice/consultationSlice';
import searchSlice from '@/slice/searchSlice';
import topDoctorsDetailsSlice from '@/slice/fetchDoctorsDetails';
import faqsSlice from '@/slice/faqsSlice';
import hospitalDetailsSlice from '@/slice/hospitalDetailsSlice';
import treatmentListDetailsSlice from '@/slice/treatmentListDetailsSlice';
import treatmentDetailsSlice from '@/slice/treatmentDetailsSlice';
import assistantDetails from '@/slice/fetchAssistanceDetails';
import expertsSlice from '@/slice/expertsSlice';
import dataSlice from '@/slice/countryCitySlice';
import termsOfUsesSlice from '@/slice/tAndcSlice';
import privacyPolicySlice from '@/slice/privacyPolicySlice';
import localeReducer from '@/slice/localeSlice';
import blogDetails from '@/slice/blogBySlug';
import successStoryDetailsReducer from '@/slice/storyBySlug';
import howItWorksDetailsReduces from '@/slice/howItsWorkDetailsSlice';
import statisticsReducer from '@/slice/statisticsSlice';
import treatmentBannerDetailsSlice from '@/slice/treatmentBannerDetailsSlice';
import bannerDetails from '@/slice/fetchBannerDetailsByLinkSlice';
import treatmentBannerDetailsWithSlug from '@/slice/treatmentBannerDetailsWithSlugSlice';
import treatmentPackageDetailsSlice from '@/slice/treatmentPackageDetailsSlice';
import fitnessSlice from '@/slice/fitnessSlice';
import fitnessBlogs from '@/slice/fitnessBlogsSlice';
import fitnessBlogsSliceBySlug from '@/slice/fitnessBlogsSliceBySlug';
import expertDetailsReducer from '@/slice/expertDetailsSlice';
import contactUsReducer from '@/slice/contactusSclice';
import aboutReducer from '@/slice/aboutSlice';

export default configureStore({
    reducer: {
        fitness: fitnessSlice,
        treatmentPackageDetails: treatmentPackageDetailsSlice,
        treatmentBannerDetailsWithSlug: treatmentBannerDetailsWithSlug,
        treatmentBannerDetails: treatmentBannerDetailsSlice,
        statistics: statisticsReducer,
        treatments: treatmentsReducer,
        treatments_banner: treatmentBannersSlice,
        top_doctors: topDoctorsSlice,
        top_hospitals: topHospitalsSlice,
        howItWorks: howItWorksSlice,
        blogs: blogsSlice,
        blogDetails: blogDetails,
        assistances: assistancesSlice,
        successStories: successStoriesSlice,
        treatmentPackage: treatmentPackageSlice,
        postPartnersLead: partnersLeadsSlice,
        consultation: consultationSlice,
        search: searchSlice,
        topDoctorsDetails: topDoctorsDetailsSlice,
        faqs: faqsSlice,
        hospitalDetails: hospitalDetailsSlice,
        treatmentListDetails: treatmentListDetailsSlice,
        treatmentDetails: treatmentDetailsSlice,
        assistantDetails: assistantDetails,
        experts: expertsSlice,
        data: dataSlice,
        termsOfUses: termsOfUsesSlice,
        privacyPolicies: privacyPolicySlice,
        locale: localeReducer,
        successStoryDetails: successStoryDetailsReducer,
        howItWorksDetails: howItWorksDetailsReduces,
        bannerDetails: bannerDetails,
        fitnessBlogs: fitnessBlogs,
        fitnessBlogsSliceBySlug:fitnessBlogsSliceBySlug,
        expertDetails: expertDetailsReducer,
        contactUs: contactUsReducer,
        about: aboutReducer,
    },
});
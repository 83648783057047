import Link from 'next/link';
import './Footer.scss';
import { footerInfo } from '@/utils/data';
import { useIntl } from 'react-intl';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from 'react-icons/fa';

export default function Footer() {
  const intl = useIntl();

  const socialIcons = [
    {
      icon: FaFacebookF,
      link: 'https://www.facebook.com/people/FitSib/61556122269592/',
      color: '#1877F2',
      label: 'Visit our Facebook page',
    },
    {
      icon: FaTwitter,
      link: '#',
      color: '#1DA1F2',
      label: 'Follow us on Twitter'
    },
    {
      icon: FaInstagram,
      link: '#',
      color: '#E4405F',
      label: 'Follow us on Instagram'
    },
    {
      icon: FaLinkedinIn,
      link: 'https://www.linkedin.com/company/fitsib1/',
      color: '#0A66C2',
      label: 'Visit our LinkedIn page',
    },
    {
      icon: FaYoutube,
      link: '#',
      color: '#FF0000',
      label: 'Visit our YouTube channel'
    },
  ];

  return (
    <div className="footerWrapper">
      <div className="footerContainer flex flex-row justify-between">
        <div>
          {footerInfo.map((info, i) => {
            return (
              <div className="flex flex-col infoContainer" key={i}>
                <div className="flex flex-col items-center justify-center w-full gap-2 sm:gap-10 sm:justify-between sm:flex-row">
                  {info.values?.map((val, j) => {
                    const linkText = intl.formatMessage({ id: val.name });
                    return val.link !== '' ? (
                      <Link className="link" href={val.link} key={j} aria-label={linkText}>
                        <div className="listValues">{linkText}</div>
                      </Link>
                    ) : (
                      <div className="listValues" key={j}>
                        {linkText}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        <div className="socialIcons">
          {socialIcons.map((social, index) => (
            <a
              key={index}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
              className="socialIcon"
              style={{ color: social.color }}
              aria-label={social.label} // Added aria-label for social links
            >
              <social.icon />
            </a>
          ))}
        </div>
      </div>
      <div className="company">
        <div className="companyContainer">
          <p className="name">
            {intl.formatMessage({
              id: 'Fitsib Inc.',
            })}
          </p>
        </div>
        <div className="companyContainer">
          <p className="copy">
            {intl.formatMessage({
              id: '© 2024 FitSib All Rights Reserved',
            })}
          </p>
        </div>
      </div>
    </div>
  );
}

'use client';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';

export const fetchHospitalDetails = createAsyncThunk(
  'hospitalDetails/fetchHospitalDetails',
  async (slug) => {
    const response = await axios.get(`top-hospitals`, {
      params: {
        filters: { slug: { $eq: slug } },
        populate: 'hospital_detail,hospital_detail.guest_houses,hospital_detail.gallary,hospital_detail.guest_houses.img,top_doctors,top_doctors.profile,img,treatments,treatments.img,hospital_detail.faqs,hospital_detail.seo,hospital_detail.seo.metaImage,hospital_detail.seo.metaSocial.image',
      },
    });
    return response.data;
  }
);

const initialState = {
  data: null,
  status: 'idle',
  error: null,
};

const hospitalDetailsSlice = createSlice({
  name: 'hospitalDetails',
  initialState,
  reducers: {
    clearState: (state) => {
      state.data = null;
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHospitalDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchHospitalDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { clearState } = hospitalDetailsSlice.actions;

export default hospitalDetailsSlice.reducer;

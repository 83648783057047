import React, { Suspense, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic'; // Import for Next.js to handle dynamic imports and disabling SSR
import { SkeletonTheme } from 'react-loading-skeleton';
import { Toaster } from 'react-hot-toast';

// Dynamic import for IntlProvider
const DynamicIntlProvider = dynamic(() => import('react-intl').then(mod => mod.IntlProvider), { ssr: false });

// Dynamic import for Navbar and Footer
const DynamicNavbar = dynamic(() => import('./Navbar/Navbar'), { ssr: false });
const DynamicFooter = dynamic(() => import('./Footer/Footer'), { ssr: false });

const LanguageWrapper = ({ children }) => {
  const lang = useSelector((state) => state.locale.locale);
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    const loadLocaleData = async () => {
      switch (lang) {
        case 'en':
          setMessages(await import('../components/locales/en/common.json'));
          break;
        case 'ar':
          setMessages(await import('../components/locales/ar/common.json'));
          break;
        case 'fr':
          setMessages(await import('../components/locales/fr/common.json'));
          break;
        case 'ru':
          setMessages(await import('../components/locales/ru/common.json'));
          break;
        case 'tl':
          setMessages(await import('../components/locales/tl/common.json'));
          break;
        case 'id':
          setMessages(await import('../components/locales/id/common.json'));
          break;
        case 'ms':
          setMessages(await import('../components/locales/ms/common.json'));
          break;
        case 'bn':
          setMessages(await import('../components/locales/bn/common.json'));
          break;
        case 'am':
          setMessages(await import('../components/locales/am/common.json'));
          break;
        case 'kz':
          setMessages(await import('../components/locales/kz/common.json'));
          break;
        case 'uz':
          setMessages(await import('../components/locales/uz/common.json'));
          break;
        case 'tz':
          setMessages(await import('../components/locales/tz/common.json'));
          break;
        case 'ha':
          setMessages(await import('../components/locales/ha/common.json'));
          break;
        case 'ja':
          setMessages(await import('../components/locales/ja/common.json'));
          break;
        case 'es':
          setMessages(await import('../components/locales/es/common.json'));
          break;
        case 'pt':
          setMessages(await import('../components/locales/pt/common.json'));
          break;
        default:
          setMessages(await import('../components/locales/en/common.json'));
      }
    };

    loadLocaleData();
  }, [lang]);

  if (!messages) {
    return <div>Loading...</div>; // Show a loading indicator until the locale data is loaded
  }

  return (
    <div>
      <DynamicIntlProvider locale={lang} messages={messages}>
        {/* Dynamically loaded Navbar */}
        <Suspense fallback={<div>Loading Navbar...</div>}>
          <DynamicNavbar />
        </Suspense>

        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <div>{children}</div>
        </SkeletonTheme>

        {/* Toaster for notifications */}
        <Toaster />

        {/* Dynamically loaded Footer */}
        <Suspense fallback={<div>Loading Footer...</div>}>
          <DynamicFooter />
        </Suspense>
      </DynamicIntlProvider>
    </div>
  );
};

export default LanguageWrapper;

'use client';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';

export const fetchFAQs = createAsyncThunk(
  'faqs/fetchFAQs',
  async (categorySlug) => {
    const response = await axios.get(`faqs`, {
      params: {
        filters: { categorySlug: { $eq: categorySlug } },
        populate: 'category',
      },
    });
    return response.data;
  }
);

const initialState = {
  data: null,
  status: 'idle',
  error: null,
};

const faqsSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    clearState: (state) => {
      state.data = null;
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFAQs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFAQs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchFAQs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { clearState } = faqsSlice.actions;

export default faqsSlice.reducer;

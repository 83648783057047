import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';


// Async thunk for fetching 'how-it-works' data
export const fetchHowItWorks = createAsyncThunk(
  'howItWorksDetailsnj/fetchHowItWorks',
  async ({ locale = 'en', slug }) => {
    const queryString = `how-it-works?populate=img&locale=${locale}&filters[slug]=${slug}`;

    const response = await axios.get(queryString);

    // const response = await axios.get(
    //     `http://localhost:1337/api/how-it-works?populate=img&locale=${locale}&filters[slug]=${slug}`
    //   );
    return response.data;
  }
);

const initialState = {
  data: null,
  status: 'idle',
  error: null,
};

const howItWorksDetailsSlice = createSlice({
  name: 'howItWorksDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHowItWorks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHowItWorks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchHowItWorks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default howItWorksDetailsSlice.reducer;

export const selectHowItWorksData = (state) => state.howItWorksDetails.data;
